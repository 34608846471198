.profileInfoStyles {
    margin-left: -250px;
    margin-top: 50px;
}
.basic-tabs .ant-tabs-tab-btn {
    color: white;
}

.profile-title {
    position: relative;
    padding-bottom: 10px;
}

.profile-title:after {
    content : "";
    position: absolute;
    left    : 0;
    bottom  : 0;
    height  : 1px;
    width   : 50%;  /* or 100px */
    border-bottom: 0.5px solid rgba(79, 79, 79, .3);
}

.profile-editor-input-fields {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 450px;
}

.upload-box {
    width: 150px;
    height: 150px;
    border: 1px solid #e6e5e8;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.personal-items {
    display: flex;
    gap: 100px;
}

.plus-icon {
    color: #17202A;
    background-color: #e8eaeb;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
}

.plus-icon:hover {
    background-color: #d9dbdb;
}