.board-layout {
    list-style: none;
    font-family: 'Roboto', 'Montserrat';
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    padding: 0;
    width: 220px;
}


.kanban-item {
    padding: 5px 10px;
    border: 1px solid #e6e5e8;
    border-radius: 4px;
    background-color: white;
    transition: 0.3s;
}

.boards-container {
    display: flex;
    gap: 40px;
}

.boards-container h4 {
    box-shadow: 0 -1px 1px 0 rgb(38 50 56 / 5%), 0 1px 2px 0 rgb(38 50 56 / 20%);
    text-transform: uppercase;
    background-color: white;
    padding: 10px 0 10px 5px;
    border-radius: 4px;
}

.border-top-blue {
    border-top: 2px solid;
    border-top-color: #7DEDFF;
}

.border-top-red {
    border-top: 2px solid;
    border-top-color: #FD3A69;
}

.border-top-yellow {
    border-top: 2px solid;
    border-top-color: #FFE227;
}

.border-top-green {
    border-top: 2px solid;
    border-top-color: #28FFBF;
}

.board {
    transition: 0.5s;
    height: 100vh;
    overflow-y: scroll;
}

.board h4 {
    margin-top: 0;
}

.board:hover {
    background-color: #efeff0;
}

.new-task-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.d-none {
    opacity: 0;
}

.z-index-2 {
    z-index: 2;
}

.new-task-form {
    position: absolute;
    background-color: white;
    height: 100px;
    width: 200px;
    margin-top: 0px;
    transition: 0.3s;
    border-radius: 4px;
    border: 0.1px solid rgb(240, 109, 240);
    padding: 5px 10px;

}

.new-task-form input {
    outline: none;
    border: none;
}

.new-task-form-inner {
    display: flex;
    flex-direction: column;
}

.new-task-form-options {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: small;
    color: white;
}

.new-task-form-options p {
    background-color: darkgrey;
    border-radius: 4px;
    padding: 2px 4px;
    cursor: pointer;
}

.task-comment {
    font-size: small;
    color: gray;
    padding: 10px 0;
}

.task-pipeline {
    font-size: small;
    color: #FD3A69;
    padding-bottom: 10px;
}

.mark-as-done {
    color: gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-size: small;
    transition: 0.3s;
}

.mark-as-done:hover {
    color:#28FFBF;
}

/* For WebKit based browsers (e.g., Chrome, Safari) */
.scrollable-content::-webkit-scrollbar {
width: 3px; /* Adjust the width as per your preference */
}

.scrollable-content::-webkit-scrollbar-track {
background: #f1f1f1; /* Adjust the track background color */
}

.scrollable-content::-webkit-scrollbar-thumb {
background: #bdbbbb; /* Adjust the thumb color */
}

/* For Firefox */
.scrollable-content {
scrollbar-width: thin; /* Adjust the scrollbar width as per your preference */
}

.scrollable-content::-moz-scrollbar-track {
background: #f1f1f1; /* Adjust the track background color */
}

.scrollable-content::-moz-scrollbar-thumb {
background: #888; /* Adjust the thumb color */
}