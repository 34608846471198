@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');
.message-box {
    border-radius: 3px;
    padding: 12px;
    color: #4F4F4F;
    background-color: #F1F1F1;
    width: 60%;
    margin-top: 20px;
    overflow: hidden;
    font-family: 'Montserrat';
    font-size: small;
}

.centered-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
}