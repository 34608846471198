.box-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.search-container {
    display: flex;
    gap: 10px;
    height: 32px;
}

.circular-progress {
    position: absolute;
    left: 50vw;
    top: 50vh;
}

.section-container {
    margin-bottom: 50px;
}