.custom-node {
    height: 120px;
    width: 150px;
    border: 1px solid #e6e5e8;
    border-radius: 4px;
    background-color: #fff;
}

.node-handle-pass {
    background-color: #28FFBF;
    transition: 0.5s;
}

.node-handle-fail {
    background-color: red;
    transition: 0.5s;
}

.node-handle:hover {
    height: 15px;
    width: 15px;
}

.custom-node-options {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    position: absolute;
    top: 83px;
}

.custom-node-options p {
    color: #768290;
    font-size: 10px;
    margin: 0;
    padding-right: 10px;
}

.custom-node-header {
    display: flex;
    align-items: center;
    color: #768290;
    font-size: 10px;
}

.custom-node-heade-icon {
    height: 10px;
}

.custom-node-handles {
    width: 100%;
    height: 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.speed-dial {
    height: 65px;
    width: 65px;
    background-color: #263238;
    color: white;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.speed-dial:hover {
    background-color: #303e45;
    cursor: pointer;
}

.custom-node-delete-node {
    color: white;
    background-color: rgb(248, 85, 85);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: -3px;
    top: -3px;
    transition: 0.3s;
    opacity: 0;
}

.custom-node-delete-node:hover {
    opacity: 1;
}

.blueprint-list {
    margin-top: 20px;
}

.blueprint-box {
    cursor: pointer;
    border: 1px solid #e6e5e8;
    background-color: #fff;
    padding: 15px 10px;
    margin-bottom: 10px;
    width: 50vw;
    transition: 0.3s;
}

.blueprint-box:hover {
    background-color: #efeff0;
}

.blueprint-box p {
    margin: 0;
    font-weight: 500;
}

.unselected-start {
    fill: "#909497";
    cursor: pointer;
}

.selected-start {
    fill: "#8E44AD";
    cursor: pointer;
}