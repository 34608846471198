.main-button {
    position: relative;
    color: white;
    background-color: #4fbbb7;
    display: flex;
    justify-content: center;
    width: fit-content;
    border-radius: 4px;
    transition: 0.2s;
}

.button-inner p {
    margin: 0;
    padding: 0.4rem 0.8rem;
    display: flex;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.main-button:hover {
    cursor: pointer;
    background-color: #3f9592;
}

.secondary-button {
    position: relative;
    color: white;
    background-color: #424246;
    display: flex;
    justify-content: center;
    width: fit-content;
    border-radius: 4px;
    transition: 0.2s;
}

.secondary-button:hover {
    cursor: pointer;
    background-color: #19191b;
}

.danger-button {
    position: relative;
    color: white;
    background-color: #be2f2f;
    display: flex;
    justify-content: center;
    width: fit-content;
    border-radius: 4px;
    transition: 0.2s;
}

.danger-button:hover {
    cursor: pointer;
    background-color: #7F1D1D;
}

.outline-button {
    position: relative;
    color: white;
    background-color: #2980B9;
    display: flex;
    justify-content: center;
    width: fit-content;
    border-radius: 4px;
    transition: 0.2s;
}

.outline-button:hover {
    cursor: pointer;
    background-color: #2571a3;
}

.loading-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-icon {
    margin-right: -0.5rem;
    margin-left: 0.5rem;
    animation: spin 1s linear infinite;
}

.system-button {
    position: relative;
    background-color: #ecf0f1;
    display: flex;
    justify-content: center;
    width: fit-content;
    border-radius: 4px;
    transition: 0.2s;
    color: #424246;
}

.system-button:hover {
    cursor: pointer;
    background-color: #d5d8d9;
}

.plain-button p {
    font-size: 0.9rem;
    font-weight: 300;
    display: flex;
    align-items: center;
}

.plain-button {
    display: flex;
    align-items: center;
    cursor: pointer;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
