.subitem-selected {
    margin: 0 5px 0 5px;
    border-radius: 2px;
    animation: appear 0.3s;
    background-color: rgba(240, 240, 240, 1);
}

@keyframes appear {
    0%   {background-color: rgba(240, 240, 240, 0);}
    100%  {background-color: rgba(240, 240, 240, 1);}
  }