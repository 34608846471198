.modal-list-hipercube {
    font-family: 'Roboto', 'Helvetica', sans-serif;
    padding: 10px 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    width: 500px;
}

.modal-list-hipercube:hover {
    background-color: #F5F5F5;
}