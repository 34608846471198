.top-bar-container {
    /*box-shadow: 0 -1px 1px 0 rgb(38 50 56 / 5%), 0 1px 2px 0 rgb(38 50 56 / 20%);*/
    height: 40px;
    width: 100vw;
    opacity: 1;
    background-color: #323452;
    color: white;
    position: absolute;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.top-bar-inner {
    position: relative;
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    padding-right: 30px;
    padding-left: 15px;
    cursor: pointer;
    font-weight: 600;
}

.top-bar-option {
    display: flex;
    align-items: center;
    gap: 5px;
}

.top-bar-option:hover {
    color: #5298c7;
}

.top-bar-logout {
    margin-top: 5px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    gap: 5px;
}

.top-bar-logout:hover {
    color: #7F1D1D;
}

.transition {
    transition: 0.3s;
}

.rotate {
    transform: rotate(180deg);
}

.search-input-top-bar {
    margin: 0 auto;
    padding-left: 190px;
    height: 100%;
    display: flex;
    align-items: center;
    width: 350px;
}

.search-input-top-bar input {
    background-color: #474964;
    color: white;
    transition: 0.5s;
    height: 32px;
}

.search-input-top-bar input::placeholder {
    color: rgb(206, 206, 206);
    font-size: 14px;
}

.search-input-top-bar input:hover {
    background-color: #5B5C74;
}

.search-input-top-bar button {
    background-color: #474964;
    border: none;
}

.search-input-top-bar .ant-btn-icon {
    color:rgb(206, 206, 206);
}

.top-navbar-search-result {
    font-family: monospace;
    line-height: 1.1;
    font-size: "small";
    background-color: #19406c;
    color: white;
    border-radius: 4px;
    padding: 5px;
}

.top-navbar-search-link {
    cursor: pointer;
    transition: 0.3s;
}

.top-navbar-search-link:hover {
    color: #388AE5;
}