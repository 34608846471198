@import url('https://fonts.googleapis.com/css2?family=Lora:wght@700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
    background-color: #F6F8F9;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

header {
    background-color: #11192A;
}

/*
p {
    font-family: 'IBM Plex Sans', sans-serif;
}

h1, h2, h3, h4 {
    font-family: 'IBM Plex Sans', sans-serif;
}
*/

a {
    text-decoration: none;
    color: inherit;
}

.app-grid {
    display: flex;
    gap: 10px;
}

p,h1,h2,h3,h4,h5 {
    font-family: 'Roboto', 'Montserrat';
}

h1 {
    font-size: 1.5em;
}

.MuiDataGrid-cell {
    min-height: 30px !important;
    height: 30px !important;
}

.MuiDataGrid-row {
    min-height: 30px !important;
    height: 30px;
}

.MuiDataGrid-virtualScrollerContent {
    height: auto !important;
}
