@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.logo-name {
    /*background: linear-gradient(-60deg,#a84bf8 0,#eb2ea2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;*/
    color: white;
    font-family: 'Poppins', 'Roboto', sans-serif;
    margin: 0;
}

.login-background {
    background: linear-gradient(135deg, #281483 0%, #CF80D8 100%);
    height: 100vh;
    width: 100%;
    background-color: black;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto Mono';
}

.login-form-container {
    /*
    color: #3c3c3c;
    width: 800px;
    height: 400px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.12), 0 2px 3px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    */
    background: rgba(255, 255, 255, 0.21);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.6px);
    -webkit-backdrop-filter: blur(4.6px);
    color: white;
    width: 350px;
}

.login-form-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 30px 25px 30px;
    align-items: center;
}

.login-presentation {
    margin-bottom: 120px;
}

.login-presentation-text {
    padding: 10px 5px 5px 30px;
}

.login-presentation-text h1 {
    font-size: 4rem;
}

.I {
    background: -webkit-linear-gradient(-90deg, rgba(246,158,117,1) 39%, rgba(148,43,158,1) 81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rotating-cube {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.cube-arrow {
    border-top: 2px dashed #3c3c3c;;
    border-right: 2px dashed #3c3c3c;
    border-radius: 0px 50px 0px 0px;
    width: 50px;
    height: 50px;
    margin-left: 230px;
    transform: rotateZ(100deg);
}

.cube-font {
    font-family: 'Roboto Mono', monospace;
    margin-left: 270px;
}

.login-button-container {
    margin-top: 20px;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
}

.registration-form-container {
    height: 400px;
    width: 600px;
    background-color: #F9F9F9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.registration-form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toggle-login-screen {
    cursor: pointer;
}

.toggle-login-screen:hover {
    background: #F9F9F9;
}


/* CUBE STYLES */

.t4db9i7fnh8 {
    position: absolute;
    transform-style: preserve-3d;
    animation: rotation 30s linear infinite;
    transform: rotateX(21deg) rotateY(14deg);
    margin-top: 15px;
    
}

.t4db9i7fnh8 .face {
position: absolute;
}

.t4db9i7fnh8 .-front {
width: 100px;
height: 100px;
transform: translate(-50%, -50%) rotateY(0deg) translateZ(50px);
background: rgba(14, 33, 73, 0.59);
}

.t4db9i7fnh8 .-left {
width: 100px;
height: 100px;
transform: translate(-50%, -50%) rotateY(90deg) translateZ(50px);
background: rgba(-1, 18, 58, 0.59);
}

.t4db9i7fnh8 .-top {
width: 100px;
height: 100px;
transform: translate(-50%, -50%) rotateX(90deg) translateZ(50px);
background: rgba(19, 38, 78, 0.59);
}

.t4db9i7fnh8 .-bottom {
width: 100px;
height: 100px;
transform: translate(-50%, -50%) rotateX(-90deg) translateZ(50px);
background: rgba(-21, -2, 38, 0.59);
}

.t4db9i7fnh8 .-right {
width: 100px;
height: 100px;
transform: translate(-50%, -50%) rotateY(-90deg) translateZ(50px);
background: rgba(-1, 18, 58, 0.59);
}

.t4db9i7fnh8 .-back {
width: 100px;
height: 100px;
transform: translate(-50%, -50%) rotateY(180deg) translateZ(50px);
background: rgba(-11, 8, 48, 0.59);
}

.pill-match {
    font-size: small;
    
    border-radius: 2px;
    display: flex;
    justify-content: center;
}

.pill-match p {
    margin: 0;
    padding: 0;
}

.pill-match-green {
    background-color: #76D7C4;
}

.pill-match-red {
    background-color: #EC7063;
}

@keyframes rotation {
    0% {
        transform: rotateZ(200deg) rotateX(180deg) rotateY(180deg);
    }

    50% {
        transform: rotateZ(-200deg) rotateX(-180deg) rotateY(-180deg);
    }

    100% {
        transform: rotateZ(200deg) rotateX(180deg) rotateY(180deg);
    }
}