.waveform-canvas {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.waveform-canvas img {
    height: 150px;
    width: 80%;
}

.waveform-bar canvas {
  height: 0;
  width: 0;
  /*border-radius: 10px;
  background: rgb(179, 195, 204);
  background: linear-gradient(90deg, rgba(108,147,168,1) 40%, rgba(78,104,118,1) 100%);*/
}

.call-animation {
  background: #ffffff;
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  animation: play 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

}

@keyframes play {

  0% {
      transform: scale(1);
  }
  15% {
      box-shadow: 0 0 0 5px rgba(17, 120, 100, 0.4);
  }
  25% {
      box-shadow: 0 0 0 10px rgba(20, 143, 119, 0.4), 0 0 0 20px rgba(255, 255, 255, 0.2);
  }
  25% {
      box-shadow: 0 0 0 15px rgba(26, 188, 156, 0.4), 0 0 0 30px rgba(255, 255, 255, 0.2);
  }
  35% {
    box-shadow: 0 0 0 25px rgba(118, 215, 196, 0.4), 0 0 0 50px rgba(255, 255, 255, 0.2);
}

}

.session-under-way {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
}

.end-call {
  background-color: #E74C3C;
  position: absolute;
  left: 10px;
  color: white;
}

.end-call-recording {
  background-color: #E74C3C;
  position: relative;
  color: white;
}

.end-call:hover, .end-call-recording:hover {
  color: blue;
}

.message-box-light {
    border-radius: 10px;
    padding: 12px;
    color: white;
    background: rgb(102,169,230);
    background: linear-gradient(45deg, rgba(102,169,230,1) 48%, rgba(134,67,255,1) 100%);
    width: 50%;
    margin-top: 20px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.message-box-light h2 {
  margin-bottom: 0;
}

.message-box-light p {
  margin-top: 0;
}

.message-box-test {
  border-radius: 3px;
  padding: 12px;
  color: #4F4F4F;
  background-color: #F1F1F1;
  font-family: 'Montserrat';
  width: 50%;
  margin-top: 20px;
  font-size: small;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}


.circle {
    border-radius: 100%;
    width: 15px;
    height: 15px;
    background-color: #ff4141;
  }
  
.text-danger-glow {
  color: #ff4141;
  box-shadow: 0 0 3px #f00, 0 0 3px #f00, 0 0 4px #f00, 0 0 5px #f00, 0 0 6px #f00, 0 0 7px #f00, 0 0 8px #f00;
}

.blink {
  animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}
@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}

.glowing-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  margin: 0;
}

.glowing-section h4 {
  margin-top: 0;
}

.glowing-flex {
  height: 100%;
  width: 15px;

}

.waveform-bar canvas {
  width: 80% !important;
}

.waveform-elements {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.waveform-elements svg {
  transform: translateY(-100%);
}

.test-creator-inputs {
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 30px;
}

.individual-inputs {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.individual-inputs-secondary {
  display: flex;
  flex-direction: row;
  width: 800px;
  gap: 15px;
}

.test-upload-audio {
  display: flex;
  flex-direction: column;
}

.new-test-input {
  font-size: xx-large;
  color: red;
}

.transcript-container {
  width: 400px;
  height: 600px;
  border: 0.915825px solid #e6e5e8;
  padding: 20px 20px;
  overflow: scroll;
}

.transcript-container p {
  padding: 0;
  margin: 0;
}

.submission-elements {
  display: flex;
  gap: 20px;
  margin-bottom: 50px;
}

.submission-controls {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.small-font-tests svg {
  font-size: 1.2rem;
}

.danger {
  color: #DC3545;
}

.assessment-settings-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 500px;
}

.d-hidden {
  display: none;
}

.d-flex {
  display: flex;
}

.transcript-block-container {
  height: 500px;
  width: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 5px;
}

.transcript-block p {
  margin: 0;
  padding: 0;
}

.transcript-block {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px;
}
