.box-tags {
    display: flex;
    padding: 0;
    list-style: none;
    gap: 8px;
}

.box-tags li {
    background-color: #EAF2F8;
    border-radius: 5px;
    padding: 2px 5px;
}

.header-1 {
    height: 12.5px;
    background: rgb(142,76,255);
    background: linear-gradient(90deg, rgba(142,76,255,1) 0%, rgba(156,228,193,1) 96%);
}

.header-2 {
    height: 12.5px;
    background: rgb(246,158,117);
    background: linear-gradient(90deg, #FFEA20 39%, #00F5FF 81%);
}

.header-3 {
    height: 12.5px;
    background: rgb(154,231,247);
    background: linear-gradient(90deg, rgba(154,231,247,1) 39%, rgba(227,113,254,1) 81%);
}

.header-4 {
    height: 12.5px;
    background: rgb(3,255,180);
    background: linear-gradient(45deg, rgba(3,255,180,1) 14%, rgba(255,249,67,1) 100%);
}
.header-5 {
    height: 12.5px;
    background: linear-gradient(45deg, rgba(224,90,247,1) 14%, rgba(241,131,154,1) 100%);
}

.header-6 {
    height: 12.5px;
    background: linear-gradient(45deg, rgba(247,90,183,1) 14%, rgba(241,203,131,1) 100%);
}

.header-7 {
    height: 12.5px;
    background: linear-gradient(45deg, rgba(90,247,150,1) 14%, rgba(131,178,241,1) 100%);
}

.header-8 {
    height: 12.5px;
    background: rgb(3,255,243);
    background: linear-gradient(45deg, rgba(3,255,243,1) 14%, rgba(67,131,255,1) 100%);
}

.header-9 {
    height: 12.5px;
    background: rgb(235,3,255);
    background: linear-gradient(45deg, rgba(235,3,255,1) 14%, rgba(137,255,67,1) 100%);
}

.header-10 {
    height: 12.5px;
    background: linear-gradient(45deg, rgba(90,183,247,1) 14%, rgba(241,223,131,1) 100%);
}

.box-languages {
    font-family: 'Roboto', sans-serif;
    font-size: small;
}