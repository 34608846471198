.sticky-head-table-manager {
    width: 850px;
}

.invite-users-controls {
    display: flex;
    gap: 50px;
    margin-bottom: 10px;
}

.invite-users-controls .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
}

.invite-users-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.userAccountType-invited {
    background-color: #D6EAF8;
    padding: 0 5px;
    border-radius: 4px;
}

.userAccountType-enabled {
    background-color: #DCF2E2;
    padding: 0 5px;
    border-radius: 4px;
}

.userAccountType-disabled {
    background-color: #FCE5EF;
    padding: 0 5px;
    border-radius: 4px;
}

.userAccountType-deleted {
    background-color: grey;
    padding: 0 5px;
    border-radius: 4px;
}

.user-snap-profile-name {
    cursor: pointer;
}