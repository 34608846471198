.language-item p {
    margin: 0;
}

.language-item {
    background-color: #e8eaeb;
    width: 500px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    position: relative;
}

.language-item-delete-forever {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    height: 24px;
    cursor: pointer;
    transition: 1s;
}

.language-item-delete-forever:hover {
    color: #808080;
}