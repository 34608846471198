@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');


.section-container {
    margin-left: 240px;
    margin-top: 50px;
    margin-right: 40px;
}

.d-flex-column-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.profile-items-container {
   
    height: 220px;
    margin-bottom: 100px;
    border-radius: 10px;
}

.profile-background-interpreter {
    background: rgb(32,55,84);
    background: linear-gradient(45deg, rgba(32,55,84,1) 14%, rgba(175,55,185,1) 100%);

}

.profile-items img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
}

.profile-items {
    display: flex;
    padding-top: 70px;
    padding-left: 50px;
    width: 950px;
}

.profile-texts {
    color: white;
    font-family: 'Roboto', sans-serif;
    margin-left: 30px;
}

.MuiTableCell-head {
    background-color: #202D3E !important;
    color: white !important;
    width: 100%;
}

.MuiBox-root {
    
    padding: 0;
}


.profile-tests-container {
    margin-bottom: 20px;
}

.profile-tab .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    color: white !important;
}

.Mui-selected {
    font-weight: bolder !important;
}

.basic-tabs {
    padding-top: 0px;
    margin-left: 0px;
    height: 100%;
    width: 735px;
}

.sticky-head-table-profile {
    margin-left: -305px;
    margin-top: 50px;
}

.profile-picture-upload {
    background-color: #28FFBF;
    padding: 1px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clear-icon {
    transform: scale(0.8);
}