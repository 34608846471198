.sidebar-body {
    position: relative;
    width: 190px;
    height: 100%;
    background-color: #FBFCFC /*#F0F0F0*/;
    opacity: 1;
    color: #3c3c3c;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    position: fixed;
    box-shadow: 0 -1px 1px 0 rgb(38 50 56 / 5%), 0 1px 2px 0 rgb(38 50 56 / 20%);
}

.sidebar-body ul {
    padding: 0;
    list-style: none;
    width: 100%;
}

.sidebar-main-options {
    display: flex;
    cursor: pointer;
    gap: 10px;
    align-items: center;
    height: 40px;
    padding-left: 15px;
}

.sidebar-body li:hover {
    color: #6c7075;
}

#selected-navbar {
    font-weight: 500;
    color: #313131;
    /*color: white;
    background-color: #202D3E;*/
}

.navbar-selector {
    position: absolute;
    width: 4px;
    height: 40px;
    transform: translateY(12.8px);
    transition: 0.3s;
}

.sidebar-submenu-options {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.d-delete {
    height: 0;
    overflow: hidden;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
.sidebar-submenu-options li {
    animation: fadeIn 0.3s ease-in-out forwards;
    opacity: 0;
    font-weight: 400;
    padding-left: 30px;
}