.candidate-profile-banner img {
    border-radius: 50%;
    height: 150px;
}

.candidate-banner-background {
    height: 75px;
    background-color: #f3f4f5;
    z-index: -1;
    position: absolute;
    width: 100%;
    margin-left: -240px;
    margin-right: 0;
    margin-top: -10px;
}

.candidate-profile-identity-info {
    padding-top: 30px;
    padding-left: 20px;
}

.candidate-profile-banner-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;
}

.candidate-profile-contact-info {
    padding-top: 50px;
}

.candidate-profile-contact-info p {
    padding: 0;
    margin: 0;
    font-size: small;
}

.copy-icon {
    position: absolute;
    transition: 0.3s;
    cursor: pointer;
}

.copy-icon:hover {
    color: #5298c7;
}

.candidate-data-container {
    display: flex;
    padding-top: 25px;
    justify-content: flex-start;
    gap: 45px;
}

.candidate-data-table {
    border: 1px solid #d0d3d4;
}

.candidate-data-header {
    padding-right: 350px;
    opacity: 0.8;
    border-radius: 5px 5px 0 0;
    background-color: #f6f6f6;
}

.candidate-data-header p {
    margin: 0;
    padding: 5px 10px;
    font-weight: 500;
}

.candidate-data-body {
    max-width: 450px;
    max-height: 450px;
    overflow-y: scroll;
    background-color: white;
}

.candidate-data-body ul {
    margin: 0;
    padding-top: 10px;
}

.candidate-data-body li {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: 300;
    cursor: pointer;
}

.candidate-data-body li:hover {
    color: #5298c7;
    font-weight: 400;
}

.candidate-notes {
    border-radius: 5px;
    border: 1px solid rgb(232,213,288);
}

.note-author-internal {
    background-color: #DCF2E2;
    padding: 0 5px;
    border-radius: 4px;
}

/* For WebKit based browsers (e.g., Chrome, Safari) */
.candidate-data-body::-webkit-scrollbar {
    width: 2px; /* Adjust the width as per your preference */
}

.candidate-data-body::-webkit-scrollbar-track {
background: #f1f1f1; /* Adjust the track background color */
}

.candidate-data-body::-webkit-scrollbar-thumb {
background: #bdbbbb; /* Adjust the thumb color */
}

/* For Firefox */
.candidate-data-body {
scrollbar-width: thin; /* Adjust the scrollbar width as per your preference */
}

.candidate-data-body::-moz-scrollbar-track {
background: #f1f1f1; /* Adjust the track background color */
}

.candidate-data-body::-moz-scrollbar-thumb {
background: #888; /* Adjust the thumb color */
}

.green-candidate-background {
    background-color: #45b39d;
}

.notes-list {
    max-height: 450px;
    min-height: 450px;
    overflow-y: scroll;
    background-color: white;
    min-width: 450px;
}

.notes-list p {
    margin: 0;
    padding: 0;
}

.note {
    width: 300px;
    cursor: pointer;
}

.note p {
    font-size: small;
}

.note-author {
    display: flex;
    align-items: center;
    gap: 10px;
}

.note-author p {
    margin:0;
    color: #888b8d ;
    font-weight: 300;
    font-size: 10px;
}

.note-body p {
    color: #010101;
    margin-top: 0;
    margin-bottom: 0px;
}

.tree-title {
    display: flex;
    align-items: center;
    width: 200px;
    height: 50px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tree-title p {
    margin: 0;
    padding: 0;
}

.tree-chevron {
    transition: 0.3s;
}

.tree-chevron-open {
    transform: rotate(180deg);
}

.tree-content {
    transition: 0.3s;
    height: 0;
    overflow-y: hidden;
}

.tree-content-open {
    height: 100%;
}

/* SCROLLS */

/* For WebKit based browsers (e.g., Chrome, Safari) */
.notes-list::-webkit-scrollbar {
    width: 3px; /* Adjust the width as per your preference */
  }

.notes-list::-webkit-scrollbar-track {
background: #f1f1f1; /* Adjust the track background color */
}

.notes-list::-webkit-scrollbar-thumb {
background: #bdbbbb; /* Adjust the thumb color */
}

/* For Firefox */
.notes-list {
scrollbar-width: thin; /* Adjust the scrollbar width as per your preference */
}

.notes-list::-moz-scrollbar-track {
background: #f1f1f1; /* Adjust the track background color */
}

.notes-list::-moz-scrollbar-thumb {
background: #888; /* Adjust the thumb color */
}